:root {
  --theme-color: #F97794;
  --purple-color: #623AA2;
  --gradient-color: linear-gradient(135deg, #F97794 0%, #623AA2 100%);

}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(217, 217, 217, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1050;
  border-radius: 25px;
}

.icon-size {
  width: 2em;
  height: 2em;
}

.icon_size {
  width: 1.5em;
  height: 1.5em;
}

.form-control,
.form-select {
  border: 0 !important;
  border-bottom: 1px solid #5c5c5c !important;
  border-radius: 0 !important;
}

.form-control:focus {
  box-shadow: none !important;
}

.react-tel-input .flag-dropdown {
  background-color: transparent !important;
  border: none !important;
}

.react-tel-input .form-control {
  width: 100% !important;
}

.form-select:focus {
  box-shadow: none !important;
}

.bg_color {
  box-shadow: 0 4px 4px 0 rgb(0 0 0 / 24%);
  border-radius: 25px;
  overflow: hidden;
}

.fixed_width.col-lg-4 {
  max-width: 428px !important;
}

.heading_details {
  font-size: 20px;
  font-weight: 500;

}

.heading_details a {
  color: transparent;
}

.radius {
  border-radius: 25px;
  padding: 30px;
  height: 87.3vh;
  overflow-y: scroll;
}

.bottom_svg_vector {
  position: absolute;
  bottom: 0px;
}

.bottom_svg_vector svg {
  border-radius: 0 0 0 25px;
}

.radius::-webkit-scrollbar {
  width: 1px;
}

.top-10 {
  top: 23px;
  left: 11px;
}


h5 {
  font-size: 16px !important;
  font-weight: 600 !important;
}

.form-floating>label {
  z-index: 1 !important;
}

.btn-current {
  background: var(--gradient-color) !important;
  border: transparent !important;
  font-weight: 600 !important;
  border-radius: 30px !important;
  padding: 10px 28px !important;
  overflow: hidden;
  position: relative;
  display: inline-block;
  transition: all 0.3s ease;
}

.otp_input {
  width: 40px !important;
  height: 40px;
  margin: 0 5px;
  text-align: center;
  font-size: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.gradient_color {
  text-transform: uppercase;
  background: linear-gradient(90deg, #F97794 0%, #623AA2 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 20px;
  font-weight: 600;
  margin-top: 20px;
}

.otp_form p {
  color: #212529;
  font-size: 14px;
  font-weight: 500;
}

.otp_form div:first-child {
  justify-content: center;
}

.resend_otp {
  color: #212529;
  font-size: 14px;
  font-weight: 500;
  margin-top: 20px;
}

.resend_otp span {
  color: var(--purple-color);
  font-weight: 700;
}

.modal-dialog {
  max-width: 386px !important
}

.table tr td {
  padding: 10px 30px;
  font-size: 18px;
  font-weight: 600;
  background: transparent !important;
}

.table tr {
  border-bottom: 1px solid rgba(221, 221, 221, 1);

}

.table tr td:first-child {
  width: 40%;
  color: #000;
}

.table tr td:last-child {
  width: 60%;

  color: rgba(62, 62, 62, 1);
}


.btn-current:hover {
  text-decoration: none;
  color: #fff;
}

.btn-current:before {
  position: absolute;
  content: '';
  display: inline-block;
  top: -180px;
  left: 0;
  width: 30px;
  height: 100%;
  background-color: #fff;
  animation: shiny-btn1 3s ease-in-out infinite;
}

.btn-current:hover {
  opacity: .7;
}

.btn-current:active {
  box-shadow: 4px 4px 6px 0 rgba(255, 255, 255, .3),
    -4px -4px 6px 0 rgba(116, 125, 136, .2),
    inset -4px -4px 6px 0 rgba(255, 255, 255, .2),
    inset 4px 4px 6px 0 rgba(0, 0, 0, .2);
}

.bg_img {
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  /* Remove the background-image property from here */
}

.bg_img::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url(./assets/img/bg.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  opacity: 0.7; /* Set your desired opacity here */
  z-index: -1; /* Ensure the background overlay is behind content */
} 
.form-control,.form-select{
  background-color:transparent !important;
  font-weight: 600 !important;
}
.form-floating>.form-control:focus~label::after,.form-floating>.form-select~label::after ,.form-floating>.form-control:not(:placeholder-shown)~label::after{
  background: transparent !important;
}
.form-floating>.form-select~label {
  color: rgba(var(--bs-body-color-rgb), 1) !important;
  font-size: 18px !important;
  margin-bottom: 10px !important;
}
.form-floating>label {
  font-weight: 600 !important;
}
.date_field label{
  font-weight: bold !important;
}
.form-floating>.form-control-plaintext~label, .form-floating>.form-control:focus~label, .form-floating>.form-control:not(:placeholder-shown)~label, .form-floating>.form-select~label {
  color: rgba(var(--bs-body-color-rgb),1) !important;
  transform: scale(.75) translateY(-.7rem) translateX(.15rem) !important;
}
h4{
  font-size: 22px !important;
}
label.floating-label.phone_label {
  font-size: 14px;
  font-weight: 600;
  padding-left: 10px;
}
@keyframes shiny-btn1 {
  0% {
    -webkit-transform: scale(0) rotate(45deg);
    opacity: 0;
  }

  80% {
    -webkit-transform: scale(0) rotate(45deg);
    opacity: 0.5;
  }

  81% {
    -webkit-transform: scale(4) rotate(45deg);
    opacity: 1;
  }

  100% {
    -webkit-transform: scale(50) rotate(45deg);
    opacity: 0;
  }
}